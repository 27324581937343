// eslint-disable-next-line @typescript-eslint/naming-convention
export enum Page_Title {
  '/dmc' = 'Deal Managment Center',
  '/pe' = 'Payment Estimator',
  '/login' = 'Log In',
  '/register' = 'Register',
  '/help' = 'Help',
  '/contact' = 'Contact',
  '/reports' = 'Reports',
  '/home' = 'Home',
  '/ca?app=CREDITAPP' = 'Credit Application',
  '/fmv' = 'Fair Market Value Lease',
  '/trac-lease' = 'Trac Lease',
  '/uniq-structure' = 'Unique Structures',
  '/quasi-lease' = 'Quasi Lease',
  '/communication' = 'Communication',
  '/doc-lib' = 'Document Library',
  '/leasing-benefit' = 'Benefits of Leasing',
  '/lease-loan-diff' = 'Difference Between Lease and Loan',
  '/leasing-glossary' = 'Leasing Glossary',
  '/profile' = 'Profile',
  '/advantages' = 'Advantages',
  '/terms' = 'Terms',
  '/secure' = 'Secure',
  '/ca?app=IDEALEASECREDITAPP&brand=TRANSPORTATION' = 'Dealer Lease Finance',
  '/ca?app=IDEALEASECREDITAPP&brand=NFS' = 'Idealease Credit Application',
  '/ca?app=IDEALEASECREDITAPP&brand=TRANSPORTATION_CA' = 'Dealer Lease Finance',
  '/ca?app=IDEALEASECREDITAPP&brand=NFS_CA' = 'Idealease Credit Application',
  '/error' = 'Error',
  '/not-found' = 'Not Found',
  '/agreements' = 'Agreement',
  '/loans' = 'Loans',
  '/admin' = 'Administration',
  '/admin/add-user' = 'Add User',
  '/admin/select-edit-user' = 'Edit User',
  '/admin/select-edit-user/edit-user' = 'Edit User',
  '/admin/delete-user' = 'Delete User',
  '/admin/enable-disable-user' = 'Enable/Disable User',
  '/admin/additional-notifications' = 'Additional Notifications',
  '/admin/user-profile' = 'User Profile',
  '/admin/user-profile-report' = 'User Profile Report',
  '/training-lib' = 'Training Library',

  '/inter-admin' = 'Salesrep',
  '/equipment-admin' = 'Salesrep',
  '/user-admin' = 'Salesrep',
  '/business-segment' = 'Salesrep',

  'fr/dmc' = 'Centre de gestion des transactions',
  'fr/pe' = 'Estimateur de paiement',
  'fr/login' = 'Ouverture de session',
  'fr/register' = 'Inscription',
  'fr/help' = 'Aider',
  'fr/contact' = 'Contact',
  'fr/reports' = 'Rapports',
  'fr/home' = 'Accueil',
  'fr/ca?app=CREDITAPP' = 'Demande de crédit client',
  'fr/fmv' = 'Juste valeur marchande (crédit-bail à la JVM)',
  'fr/trac-lease' = 'Crédit-bail - TRAC',
  'fr/uniq-structure' = 'Structures uniques',
  'fr/quasi-lease' = 'Crédit-bail-Quasi',
  'fr/communication' = 'Communication',
  'fr/doc-lib' = 'Bibliothèque de documents',
  'fr/leasing-benefit' = 'Avantages de la location',
  'fr/lease-loan-diff' = 'Différence entre la location et le prêt',
  'fr/leasing-glossary' = 'Glossaire de location',
  'fr/profile' = 'Profil',
  'fr/advantages' = 'Avantages',
  'fr/terms' = 'Modalités',
  'fr/secure' = 'Sécurisé',
  'fr/ca?app=IDEALEASECREDITAPP&brand=TRANSPORTATION_CA' = 'Crédit-bail par le concessionnaire',
  'fr/ca?app=IDEALEASECREDITAPP&brand=NFS_CA' = 'Demande de crédit Idealease',
  'fr/error' = 'Erreur',
  'fr/not-found' = 'Introuvable',
  'fr/agreements' = 'Convention',
  'fr/loans' = 'Prêts',
  'fr/admin' = 'Administration',
  'fr/admin/add-user' = 'Ajouter un utilisateur',
  'fr/admin/select-edit-user' = 'Modifier l’utilisateur',
  'fr/admin/select-edit-user/edit-user' = 'Modifier l’utilisateur',
  'fr/admin/delete-user' = 'Supprimer l’utilisateur',
  'fr/admin/enable-disable-user' = 'Activer/désactiver l’utilisateur',
  'fr/admin/additional-notifications' = 'Notifications supplémentaires',
  'fr/admin/user-profile' = 'Profil d’utilisateur',
  'fr/admin/user-profile-report' = 'Rapports de profil d’utilisateur',
  'fr/inter-admin' = 'Administration interne',
  'fr/salesrep' = 'Représentant des ventes',
  'fr/training-lib' = 'Bibliothèque de formation',
}

// '/forget' = 'Forget',
