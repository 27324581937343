import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { ServiceResponse } from 'leasing-source-shared';
import { map, Observable, tap } from 'rxjs';
import { ContentObjectDetails } from 'src/app/model/content-object-details.dto';
import { BackEndUrl } from 'src/app/sharedutility/backend-url';

@Injectable({
  providedIn: 'root',
})
export class ContentDetailsService {
  private webContent: ContentObjectDetails[];

  private programContent: ContentObjectDetails[];

  constructor(
    private httpClient: HttpClient,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  getWebContentByPosition(contentPosition: string): ContentObjectDetails {
    return this.webContent.find((c) => {
      return c.positionName === contentPosition;
    });
  }

  getProgramContentByPosition(contentPosition: string): ContentObjectDetails {
    return this.programContent.find((c) => {
      return c.positionName === contentPosition;
    });
  }

  getContentForLS(brand: string, programCode?: string): Observable<ContentObjectDetails[]> {
    if (programCode) {
      return this.getContentDetailsForProgram(programCode, brand);
    }
    return this.getContentDetailsForBrand(brand);
  }

  refetchBasedOnLocaleChange(brand: string, programCode?: string): void {
    this.webContent = null;
    this.programContent = null;
    this.getContentForLS(brand, programCode).subscribe(() => {
      console.log('New content fetched for new locale/language');
    });
  }

  getContentDetailsForProgram(programCode: string, brand: string): Observable<ContentObjectDetails[]> {
    return this.httpClient.get<ServiceResponse<any>>(`${BackEndUrl.contentObjectDetails}/${brand}/${this.locale.language}/${programCode}`).pipe(
      map((response) => {
        if (response.result) {
          return response.result;
        }
        throw new Error('Service error');
      }),
      tap((content) => {
        this.programContent = content;
      })
    );
  }

  getContentDetailsForBrand(brand: string): Observable<ContentObjectDetails[]> {
    return this.httpClient.get<ServiceResponse<any>>(`${BackEndUrl.webContentObjectDetails}/${brand}/${this.locale.language}`).pipe(
      map((response) => {
        if (response.result) {
          return response.result;
        }
        throw new Error('Service error');
      }),
      tap((content) => {
        this.webContent = content;
      })
    );
  }
}
