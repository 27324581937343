import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentObjectDetails } from 'src/app/model/content-object-details.dto';
import { BrandingTypes } from 'src/app/service-pool/branding/branding-types.enum';
import { BrandingService } from 'src/app/service-pool/branding/branding.service';
import { ContentDetailsService } from 'src/app/service-pool/content-details/content-details.service';
import { OutletHomeService } from 'src/app/service-pool/outlet-home-service/outlet-home.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  home$: Observable<string>;

  carouselData: ContentObjectDetails = null;

  nonStandardHome = false;

  constructor(
    private contentDetailsService: ContentDetailsService,
    private brandService: BrandingService,
    private outletHomeService: OutletHomeService
  ) {}

  ngOnInit() {
    if (this.brandService?.programAttributes?.brandingTtype !== BrandingTypes.BMO) {
      this.carouselData = this.contentDetailsService.getProgramContentByPosition('home.carousel');
      this.carouselData.items.sort((prev, curr) => prev.objectOrder - curr.objectOrder);
      this.nonStandardHome = true;
    } else {
      this.nonStandardHome = false;
      this.home$ = this.outletHomeService.getHomeContent();
    }
  }
}
