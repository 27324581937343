import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { CookieService } from 'ngx-cookie';
import { Company_Title } from 'src/app/model-old/company-title';
import { BrandingTypes } from 'src/app/service-pool/branding/branding-types.enum';
import { BrandingService } from 'src/app/service-pool/branding/branding.service';
import { ContentDetailsService } from 'src/app/service-pool/content-details/content-details.service';
import { GetIdService } from 'src/app/service-pool/get-id-service.service';
import { LanguageService } from 'src/app/service-pool/language-service/language.service';
import { CONFIG } from '../../model-old/config';
import { LogoffService } from '../../service-pool/business-services/logoff-service/logoff.service';
import { ChildMessageService } from '../../service-pool/child-message-service/child-message.service';
import { LoginService } from '../../service-pool/login-service/login.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Input() header: string;

  @Input() internalPage: boolean;

  @Input() selectedLangAbbrv: string;

  @Output() childPathNavigation = new EventEmitter();

  loggedIn: boolean = false;

  brand: string;

  showCanadian: boolean = false;

  logoSrc: string;

  childCanDeactive: boolean = false;

  config: object = CONFIG;

  userName: string;

  showLanguageSelect: boolean = false;

  programStyleModifier: string = this.brandService.selectedProgram?.vendorcode || '';

  isCLAAS = false;

  isTakeuchi = false;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private loginService: LoginService,
    private cookieService: CookieService,
    protected _sanitizer: DomSanitizer,
    private childMessageService: ChildMessageService,
    private logOffService: LogoffService,
    private languageService: LanguageService,
    private brandService: BrandingService,
    private dateTimeId: GetIdService,
    private contentDetailsService: ContentDetailsService
  ) {}

  ngOnInit() {
    // Moved this logic outside of is logged in. it should run regardless of whether we are logged in or not.
    this.brand = this.cookieService.get('bid');
    this.showCanadian = !!this.brand?.toUpperCase().includes('CA');
    this.childMessageService.canDeactiveChildApp().subscribe((canDeactive) => {
      this.childCanDeactive = canDeactive;
    });
    this.loginService.getLoggedIn().subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
      if (loggedIn) {
        this.userName = this.loginService.getUser();
        this.logoSrc = this.logoSourceCreation(
          this.brand,
          this.brandService.programAttributes?.brandingTtype,
          this.brandService.selectedProgram.vendorcode
        );
        this.isCLAAS =
          this.brandService?.programAttributes?.brandingTtype === 'BMO' ||
          Company_Title?.[this.brandService?.selectedProgram?.vendorcode] === 'CFS' ||
          Company_Title?.[this.brandService?.selectedProgram?.vendorcode] === 'BMO';
        this.isTakeuchi = Company_Title?.[this.brandService.selectedProgram.vendorcode] === 'TFS';
      }
    });
    const sessionLocale = JSON.parse(sessionStorage.getItem('locale'));
    this.selectedLangAbbrv = sessionLocale.language.split('-')[0].toUpperCase();
    this.languageService.getLanguageBarStatus().subscribe((languageFlag) => {
      this.showLanguageSelect = languageFlag;
    });
  }

  safeHtml(html) {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  navigate(path, child?) {
    const canNavigate = this.childMessageService.canNavigate(this.childCanDeactive, path, child);
    if (!canNavigate) {
      return false;
    }
    this.childPathNavigation.emit({ path, child });
    return false;
  }

  toggleLanguage() {
    this.languageService.setLanguageBarStatus(this.showLanguageSelect);
  }

  logOffClicked() {
    this.logOffService.logOff();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  logoSourceCreation(brand: string, brandingTtype: BrandingTypes, vendorCode: string): string {
    if (brandingTtype && brandingTtype !== BrandingTypes.BMO) {
      const homeLogo = this.contentDetailsService.getProgramContentByPosition('home.logo');
      return homeLogo.items[0].contentPath;
    }
    return `/assets/secure/${brand}/images/Logo.png?${this.dateTimeId.getDateTimeId}`;
  }
}
