/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
import { Component, ElementRef, HostListener, Inject, isDevMode, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Event, NavigationEnd, Router } from '@angular/router';
import { L10nConfig, L10nLocale, L10nTranslationService, L10N_CONFIG, L10N_LOCALE } from 'angular-l10n';
import * as $ from 'jquery';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, EMPTY, filter, forkJoin, Subject, Subscription, switchMap } from 'rxjs';
import { v4 } from 'uuid';
import { Title } from '@angular/platform-browser';
import { l10nConfig as defaultL10n } from './l10n.config';
import { CONFIG } from './model-old/config';
import { Message } from './model-old/message';
import { NotificationMessageDTO } from './model-old/notification-message-dto';
import { NotificationModeDTO } from './model-old/notification-mode-dto';
import { UIGroup } from './model-old/ui-group';
import { CompanyDetails } from './model/company-details.dto';
import { DealerProgram } from './model/dealer-program.dto';
import { BrandingService } from './service-pool/branding/branding.service';
import { ErrorInfoService } from './service-pool/business-services/error-info-service/error-info.service';
import { GuardService } from './service-pool/business-services/guard-service/guard.service';
import { LogSetupServiceService } from './service-pool/business-services/log-setup-service/log-setup-service.service';
import { LogoffService } from './service-pool/business-services/logoff-service/logoff.service';
import { NotificationService } from './service-pool/business-services/notification-service/notification.service';
import { ChildMessageService } from './service-pool/child-message-service/child-message.service';
import { ContentDetailsService } from './service-pool/content-details/content-details.service';
import { GetIdService } from './service-pool/get-id-service.service';
import { LanguageService } from './service-pool/language-service/language.service';
import { LoadDefaultDataService } from './service-pool/load-default-data-service/load-default-data.service';
import { LoginService } from './service-pool/login-service/login.service';
import { OutletHomeService } from './service-pool/outlet-home-service/outlet-home.service';
import { PageTitleService } from './service-pool/page-title/page-title.service';
import { Page_Title } from './model/page-title';
import { Company_Title } from './model/company-title';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('languageSelect', { read: ElementRef }) languageSelectERef: ElementRef;

  @ViewChild('topNav', { read: ElementRef }) topNav: ElementRef;

  localeTemp = new FormControl('');

  stopListening: Function[];

  sentMessage: Message;

  loggedIn: boolean;

  brand: string;

  loaded: boolean;

  childLoaded: boolean;

  publicHeader: string;

  subBody: string;

  options: CookieOptions;

  internalPage: boolean;

  unprotectedPageLoaded: boolean;

  underHome: boolean;

  unprotectedPage: boolean;

  externalLandingPage: boolean;

  routePathUnderPage: string;

  internalPageLoaded: boolean;

  errorStatus: number;

  userName: string;

  userEmail: string;

  dealerName: string;

  dealerPhone: string;

  uuid: string;

  IESelfStorageFireFlag: boolean;

  display: BehaviorSubject<boolean>;

  notificationMessage: NotificationMessageDTO;

  leaveSalesrepFlag: boolean;

  allowLogOff: boolean = true;

  protectedSub: Subscription;

  unProtectedSub: Subscription;

  showLanguageSelect: boolean = false;

  selectedLanguage: string;

  defaultL10nConfig: L10nConfig;

  hideSubNav = new Subject<void>();

  modalVisible = true;

  isCanadian = false;

  blModalVisible = false;

  companyTemp: CompanyDetails = null;

  constructor(
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private zone: NgZone,
    private renderer: Renderer2,
    private loginService: LoginService,
    private router: Router,
    private loadDefaultDataService: LoadDefaultDataService,
    private cookieService: CookieService,
    private childMessageService: ChildMessageService,
    private outletHomeService: OutletHomeService,
    private guardService: GuardService,
    private logOffService: LogoffService,
    private errorInfoService: ErrorInfoService,
    private notificationService: NotificationService,
    private logger: NGXLogger,
    private logSetupService: LogSetupServiceService,
    private translation: L10nTranslationService,
    private languageService: LanguageService,
    private brandingService: BrandingService,
    private pageTitle: PageTitleService,
    private dateTimeId: GetIdService,
    private contentDetailsService: ContentDetailsService,
    private titleService: Title
  ) {
    this.stopListening = [];
    this.stopListening.push(renderer.listen('window', 'message', (event) => this.recieveMessage(event)));
    window.addEventListener('storage', (event) => this.storageListener(event), false);
    this.loggedIn = false;
    this.brand = null;
    this.loaded = false;
    this.childLoaded = true;
    this.sentMessage = null;
    this.internalPage = false;
    this.unprotectedPageLoaded = false;
    this.unprotectedPage = true;
    this.externalLandingPage = true;
    this.underHome = false;
    this.routePathUnderPage = null;
    this.internalPageLoaded = false;
    this.IESelfStorageFireFlag = true;
    this.display = new BehaviorSubject<boolean>(false);
    this.defaultL10nConfig = defaultL10n;
  }

  changeLocale() {
    const locale = this.localeTemp.value;
    if (locale) {
      const configSchema = this.l10nConfig.schema.find((item) => item.locale.language === locale);
      this.translation.setLocale(configSchema.locale);
    }
  }

  setLocale() {
    const sessionLocale = JSON.parse(sessionStorage.getItem('locale'));
    const locale = this.GetParam('locale');
    if (sessionLocale) {
      const configSchema = this.l10nConfig.schema.find((item) => item.locale.language === sessionLocale.language);
      if (configSchema) {
        this.translation.setLocale(configSchema.locale);
      } else {
        this.translation.setLocale(this.l10nConfig.defaultLocale);
      }
    } else if (locale) {
      const configSchema = this.l10nConfig.schema.find((item) => item.locale.language === locale);
      this.translation.setLocale(configSchema.locale);
    } else {
      const configSchema = this.l10nConfig.schema.find((item) => item.locale.language === this.l10nConfig.defaultLocale.language);
      this.translation.setLocale(configSchema.locale);
    }
  }

  translationChangeListener() {
    this.translation.onChange().subscribe((local) => {
      this.logger.debug('--- language selection changed ---', UIGroup.PORTAL, 'app.component.ts', '129');
      this.setAppTitle(this.router.url, this.locale.language);
      Object.entries(CONFIG).forEach(([key, value]) => {
        if (value.loaded) {
          // prepare notification dto
          this.sentMessage = {
            payload: {
              local,
            },
            departure: UIGroup.PORTAL,
            passThrough: key,
            destination: key,
          };
          this.sendMessage();
        }
      });
      // DO TO create message reviecer in other UI and send out local
    });
  }

  // prevents using backspace to nagivate backwards
  @HostListener('document:keydown', ['$event'])
  // eslint-disable-next-line consistent-return
  handleKeyboardEvents(evt: KeyboardEvent) {
    if (evt.keyCode === 8 || evt.which === 8) {
      let doPrevent = true;
      const types = [
        'text',
        'password',
        'file',
        'search',
        'email',
        'number',
        'date',
        'color',
        'datetime',
        'datetime-local',
        'month',
        'range',
        'search',
        'tel',
        'time',
        'url',
        'week',
      ];
      const target = <HTMLInputElement>evt.target;
      const disabled = target.disabled || (<HTMLInputElement>evt.target).readOnly;
      if (!disabled) {
        if (target.isContentEditable) {
          doPrevent = false;
        } else if (target.nodeName === 'INPUT') {
          let type = target.type;
          if (type) {
            type = type.toLowerCase();
          }
          if (types.indexOf(type) > -1) {
            doPrevent = false;
          }
        } else if (target.nodeName === 'TEXTAREA') {
          doPrevent = false;
        }
      }
      if (doPrevent) {
        evt.preventDefault();
        return false;
      }
    }
  }

  storageListener(event) {
    if (event.storageArea === localStorage && event.key === 'bid' && this.IESelfStorageFireFlag) {
      if (event.newValue) {
        this.brandEmulated('#/home');
      }
    } else {
      this.IESelfStorageFireFlag = true;
    }
  }

  GetParam(name) {
    const results = new RegExp(`[\\?&]${name}=([^&#]*)`).exec(window.location.href);
    if (!results) {
      return '';
    }
    return results[1] || '';
  }

  ngOnInit() {
    this.setLocale();
    this.handleNotify();
    this.languageService.getLanguageBarStatus().subscribe((languageFlag) => {
      this.showLanguageSelect = languageFlag;
    });
    this.setAppTitle();
    const brand = this.cookieService.get('bid');
    this.isCanadian = brand?.toUpperCase()?.includes('CA');
    this.userName = 'Public';
    this.uuid = v4();
    this.loginService.getLoggedIn().subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });
    this.outletHomeService.getLogoffListener().subscribe((logoff) => {
      if (logoff === true) {
        this.logOffService.logOff();
      }
    });
    this.outletHomeService.getAgreementHeader().subscribe((agreementHeader) => {
      this.logger.debug('----- agreement header ----', UIGroup.PORTAL, 'app.component.ts', '187');
      if (agreementHeader) {
        this.publicHeader = agreementHeader;
      }
    });
    this.urlListener();
    this.options = { path: '/' };
    if (!isDevMode() && window.location.hostname !== 'localhost') {
      this.options.secure = true;
    }

    this.determineModalVisibility();

    this.errorInfoService.getErrorStatus().subscribe((errorStatus) => {
      this.logger.debug('----- under portal -----', UIGroup.PORTAL, 'app.component.ts', '198');
      this.logger.debug(errorStatus, UIGroup.PORTAL, 'app.component.ts', '199');
      if (this.errorStatus !== 401) {
        this.errorStatus = errorStatus;
      }
    });
    this.translationChangeListener();
  }

  determineModalVisibility() {
    this.blModalVisible = false;
    // external + internal + not Canadian + not Logged In
  }

  handleNotify() {
    this.notificationService.getNotification().subscribe((notificationMessage) => {
      if (notificationMessage) {
        this.notificationMessage = notificationMessage as NotificationMessageDTO;
        this.display.next(true);
      }
    });
  }

  setAppTitle(url?: string, language?: any) {
    // this.pageTitle.setTitle(url, this.internalPage);
    let brand = this.cookieService.get('bid');
    if (language?.includes('fr') && url) {
      url = 'fr' + url;
    }
    if (!brand) {
      brand = '' + this.GetParam('brand');
    }
    let titleVal = Company_Title[brand] ? Company_Title[brand] : 'BMO';
    if (this.internalPage) {
      titleVal = 'BMO';
    }
    if (url) {
      if (url.includes('/ca?app=IDEALEASECREDITAPP')) {
        titleVal += ' - ' + Page_Title[url + '&brand=' + brand];
      } else if (url.includes('/salesrep')) {
        titleVal += ' - Salesrep';
      } else if (Page_Title[url]) {
        titleVal += ' - ' + Page_Title[url];
      }
    }
    this.titleService.setTitle(titleVal);
  }

  // AttentionL 3 ways to load one page, here is one
  // 1, only for refresh and open a new window
  // 2, through message, see recieveMessage function
  // 3, through click on the top-nav
  // They follow the rule, load=> send message=> url change(except 1)
  urlListener() {
    this.router.events.pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const node = document.getElementById('portalTop');
      if (node) {
        node.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      this.childLoaded = true;
      const url = event.urlAfterRedirects;
      this.logger.debug(url, UIGroup.PORTAL, 'app.component.ts');
      if (url === '/') {
        return;
      }
      this.externalLandingPage = url.startsWith('/?brand=') || url.startsWith('/login');
      this.unprotectedPage = window.location.pathname.replace('/portal', '').startsWith('/web');
      this.internalPage =
        url.startsWith('/salesrep') ||
        url.startsWith('/inter-admin') ||
        url.startsWith('/user-admin') ||
        url.startsWith('/business-segment') ||
        url.startsWith('/equipment-admin') ||
        url.startsWith('/manage-content') ||
        url.startsWith('/eapps-admin') ||
        url.startsWith('/select-salesreps');
      this.setAppTitle(url);
      if (url === '/error' || url === '/not-found') {
        this.loadExceptionPageHeader();
        return;
      }
      if (this.internalPage && this.loggedIn) {
        // this.loadSalesrepHeader(this.childLoaded);
        this.childLoaded = false;
        this.loadDefaultDataService.getSalesrepHeader().subscribe({
          next: (internalHeader) => {
            this.publicHeader = internalHeader;
            this.childLoaded = true;
          },
          error: () => {
            this.childLoaded = true;
          },
        });
      }
      this.underHome = url.startsWith('/home');
      this.checkFirstTimeIn().then(
        () => {
          this.loadChildAppOnRefresh(url);
          this.htmlChangeOnLocale();
        },
        () => {
          this.htmlChangeOnLocale();
        }
      );
    });
  }

  htmlChangeOnLocale() {
    if (this.externalLandingPage || this.unprotectedPage) {
      this.unProtectedSub = this.translation
        .onChange()
        .pipe(switchMap(() => forkJoin(this.loadDefaultDataService.getUnprotectedPageHTML(this.brand))))
        .subscribe(([header, subbody]) => {
          this.publicHeader = `${header}`;
          this.subBody = `${subbody}`;
        });
    } else if ((!this.unprotectedPage && !this.internalPage) || this.underHome) {
      this.protectedSub = this.translation
        .onChange()
        .pipe(switchMap(() => forkJoin(this.loadDefaultDataService.getProtectedPageHTML(this.brand))))
        .subscribe(([subbody, home]) => {
          this.contentDetailsService.refetchBasedOnLocaleChange(this.brand, this.brandingService.selectedProgram.vendorcode);
          this.outletHomeService.setHomeContent(`${home}`);
          this.subBody = `${subbody}`;
        });
    }
  }

  loadExceptionPageHeader() {
    this.brand = this.cookieService.get('bid');
    if (!this.unprotectedPageLoaded && !this.loggedIn) {
      if (this.brand) {
        this.loadUnprotectedPage();
      } else {
        this.loadDefaultDataService.getSalesrepHeader().subscribe({
          next: (internalHeader) => {
            this.publicHeader = internalHeader;
            this.loaded = true;
          },
          error: () => {
            this.loaded = true;
          },
        });
      }
    }
  }

  loadChildAppOnRefresh(url) {
    Object.keys(CONFIG).every((key) => {
      if (this.matchUrlToConfig(url, key) && !CONFIG[key].loaded) {
        this.navigateAway(CONFIG[key].name);
        return false;
      }
      return true;
    });
  }

  private matchUrlToConfig(url: string, key: string) {
    const childRoutes = CONFIG[key]?.childRoutes as string[];
    const matchFound = !!childRoutes?.find((cr) => {
      return url.includes(cr);
    });
    return matchFound || url.startsWith(CONFIG[key].pathRelativeToPortal.substring(1));
  }

  checkFirstTimeIn() {
    return new Promise<void>((resolve, reject) => {
      if (this.unprotectedPage && !this.unprotectedPageLoaded) {
        this.brand = `${this.GetParam('brand')}`;
        this.cookieService.put('bid', this.brand, this.options);
        this.loadUnprotectedPage(reject);
      } else if (!this.unprotectedPage && !this.internalPage && !this.loggedIn) {
        this.brand = this.cookieService.get('bid');
        this.loadProtectedPage(resolve, reject);
      } else if (this.internalPage && !this.loggedIn && !this.internalPageLoaded) {
        this.loadInternalPage(resolve);
      } else {
        resolve();
      }
    });
  }

  loadInternalPage(resolve?) {
    this.loaded = false;
    this.loadDefaultDataService.getSalesrepPage().subscribe({
      next: ([header, uiSectionsResponse]) => {
        this.publicHeader = header;
        uiSectionsResponse.result.forEach((sectionName) => {
          if (CONFIG[UIGroup[sectionName]]) {
            CONFIG[UIGroup[sectionName]].permitted = true;
          }
        });
        this.loaded = true;
        this.internalPageLoaded = true;
        this.blModalVisible = false;
        if (resolve) {
          resolve();
        }
      },
      error: () => {
        this.loaded = true;
      },
    });
  }

  loadUnprotectedPage(reject?) {
    this.loaded = false;
    forkJoin([this.loadDefaultDataService.getUnprotectedPage(this.brand), this.contentDetailsService.getContentForLS(this.brand)]).subscribe({
      next: ([data]) => {
        this.publicHeader = `${data[0]}`;
        this.subBody = `${data[1]}`;
        this.unprotectedPage = true;
        this.unprotectedPageLoaded = true;
        this.outletHomeService.setPhoneNo(data[3].result);
        this.loaded = true;
        if (!this.brand?.includes('CA')) {
          this.blModalVisible = true;
        } else {
          this.blModalVisible = false;
        }

        if (reject) {
          reject();
        }
      },
      error: () => {
        window.location.href = '#/error';
        this.loaded = true;
      },
    });
  }

  loadProtectedPage(resolve: (value: void | PromiseLike<void>) => void, reject: (reason?: any) => void) {
    this.loaded = false;
    this.loadDefaultDataService
      .getTokens()
      .pipe(
        switchMap(() => {
          return forkJoin([this.loadDefaultDataService.getAllowedSections(), this.loadDefaultDataService.getUserDetails()]);
        }),
        switchMap(([uiSections, userDetails]) => {
          uiSections.forEach((sectionName) => {
            if (CONFIG[UIGroup[sectionName]]) {
              CONFIG[UIGroup[sectionName]].permitted = true;
            }
          });
          if (!userDetails) {
            window.location.href = '#/error';
            this.loaded = true;
            this.leaveSalesrepFlag = false;
            reject();
            throw new Error('No User Details');
          }
          this.childMessageService.updateDid(`${userDetails.companyId}`);
          this.guardService.setInternalUser(`${userDetails.isSalesRep}`);
          this.userName = userDetails.user.username;
          this.userEmail = userDetails.user.email;
          this.loginService.setLoggedInUserName(`${userDetails.user.fname} ${userDetails.user.lname}`);
          return this.loadDefaultDataService.getCompanyNameAndDetails(this.childMessageService.getDid());
        }),
        switchMap((company) => {
          this.companyTemp = company;
          if (company.coname) {
            this.dealerName = company.coname;
            this.dealerPhone = company.phone1;
            this.outletHomeService.setCompanyName(`${company.coname}`);
          }
          if (company?.companyPermissions && this.guardService.getInternalUser() !== 'true') {
            let firstInUser = false;
            company.companyPermissions.forEach((permission) => {
              if (
                permission.compPermissionAttribute === 'agreementPage' &&
                (permission.compPermissionValue === 'N' || permission.compPermissionValue === 'No')
              ) {
                firstInUser = true;
                this.outletHomeService.setShowAgreementPage(true);
              }
              if (
                permission.compPermissionAttribute === 'viewedDisclaimer' &&
                (permission.compPermissionValue === 'N' || permission.compPermissionValue === 'No')
              ) {
                firstInUser = true;
                this.outletHomeService.setShowViewedDisclaimer(true);
              }
            });
            if (firstInUser) {
              this.router.navigate(['agreements']);
              this.loaded = true;
              reject();
              return EMPTY;
            }
            this.loginService.notifyLoggedIn();
          } else {
            this.loginService.notifyLoggedIn();
          }
          return this.brandingService.getDealerPrograms(company.vendorLocations[0].vendorLocation);
        }),
        switchMap((dealerPrograms) => {
          const programFromCookie = this.cookieService.getObject('pid') as DealerProgram;
          const vendorProgam =
            dealerPrograms.find((d) => d.vendorcode === programFromCookie?.vendorcode) || dealerPrograms.find((d) => d.defaultProgram);
          if (!programFromCookie) {
            this.cookieService.putObject('pid', { ...vendorProgam, vendorLocation: this.companyTemp.vendorLocations[0].vendorLocation });
          }
          return forkJoin([
            this.brandingService.getBrmsProgramAttributes(vendorProgam),
            this.contentDetailsService.getContentForLS(this.brand, vendorProgam.vendorcode),
          ]);
        })
      )
      .subscribe({
        next: () => {
          // eslint-disable-next-line eqeqeq
          if (!this.brand?.includes('CA') && this.guardService.getInternalUser() == 'true') {
            this.blModalVisible = true;
          } else {
            this.blModalVisible = false;
          }
          this.leaveSalesrepFlag = false;
          this.setAppTitle(this.router.url);
          resolve();
        },
        error: (error) => {
          console.log(error);
          window.location.href = '#/error';
          this.loaded = true;
          this.leaveSalesrepFlag = false;
          reject();
        },
        complete: () => {
          this.loaded = true;
        },
      });
  }

  brandEmulated(url) {
    this.loaded = false;
    window.location.href = url;
    window.location.reload();
  }

  sendMessage() {
    this.childMessageService.sendMessage(this.sentMessage);
    this.sentMessage = null;
  }

  recieveMessage(event) {
    const recievedMessag: Message = event.data;
    if (!recievedMessag || (recievedMessag.passThrough && recievedMessag.passThrough !== UIGroup.PORTAL)) {
      return;
    }
    if (recievedMessag.destination === UIGroup.PORTAL) {
      if (recievedMessag.payload.logOff) {
        this.outletHomeService.triggerLogoff();
      }
      if (recievedMessag.payload.userRemoved) {
        if (!this.allowLogOff) {
          return;
        }
        this.allowLogOff = false;
        this.notificationMessage = new NotificationMessageDTO({
          buttonMessage: ['Ok'],
          header: 'Warning',
          mode: NotificationModeDTO.NORMAL_MODE,
        });
        this.notificationMessage.content = this.translation.translate('PORTAL_ERROR.ACCOUNT_DELETED');
        this.notificationService.notify(this.notificationMessage);
        setTimeout(() => {
          if (this.guardService.getInternalUser() === 'true') {
            this.router.navigate(['salesrep/emulate-dealer']).then(() => {
              window.location.reload();
            });
          } else {
            this.outletHomeService.triggerLogoff();
          }
        }, 3000);
      }
      if (recievedMessag.payload.permissionChange) {
        if (!this.allowLogOff) {
          return;
        }
        this.allowLogOff = false;
        this.notificationMessage = new NotificationMessageDTO({
          buttonMessage: ['Ok'],
          header: 'Warning',
          mode: NotificationModeDTO.NORMAL_MODE,
        });
        this.logger.info(recievedMessag.payload.errorCode);
        this.notificationMessage.content =
          (recievedMessag.payload.errorCode as string).indexOf('427') >= 0
            ? this.translation.translate('PORTAL_ERROR.RLS_ERROR')
            : this.translation.translate('PORTAL_ERROR.PERMISSION_CHANGED');
        this.notificationService.notify(this.notificationMessage);

        setTimeout(() => {
          if (this.guardService.getInternalUser() === 'true') {
            this.router.navigate(['salesrep/emulate-dealer']).then(() => {
              window.location.reload();
            });
          } else {
            this.router.navigate(['home']).then(() => {
              window.location.reload();
            });
          }
        }, 3000);
      }
      if (recievedMessag.payload.showLang === true || recievedMessag.payload.showLang === false) {
        this.l10nConfig.schema = this.defaultL10nConfig.schema;
      }
      if (recievedMessag.payload.ready) {
        this.firstTimeLoadChild();
      }
      if (recievedMessag.payload.canDeactive === true || recievedMessag.payload.canDeactive === false) {
        this.leavePageAlertMessageHandle(recievedMessag);
      }
      if (recievedMessag.departure === UIGroup.SALESREP_UI && recievedMessag.payload.brand && recievedMessag.payload.dealerId) {
        this.salesrepUIMessageHandle(recievedMessag);
      }
      return;
    }
    if (!CONFIG[recievedMessag.destination]) {
      return;
    }
    if (recievedMessag.destination && recievedMessag.payload) {
      this.transferMessage(recievedMessag);
    }
  }

  // when you refresh the page, url will gets changed firstly
  // Then routePathUnderPage is still null when refresh
  firstTimeLoadChild() {
    this.childLoaded = false;
    this.sentMessage.payload.loaded = true;
    this.sendMessage();
    if (this.routePathUnderPage) {
      window.location.href = this.routePathUnderPage;
    }
    this.childLoaded = true;
  }

  transferMessage(recievedMessag) {
    this.sentMessage = {
      payload: recievedMessag.payload,
      departure: UIGroup.PORTAL,
      passThrough: recievedMessag.destination,
      destination: recievedMessag.destination,
    };
    this.addMessage(recievedMessag.destination);
    this.childLoaded = false;
    if (recievedMessag.payload.url) {
      this.routePathUnderPage = recievedMessag.payload.url;
    } else {
      this.routePathUnderPage = CONFIG[recievedMessag.destination].pathRelativeToPortal;
    }
    if (
      recievedMessag.departure === UIGroup.SALESREP_UI &&
      (recievedMessag.destination !== UIGroup.ADMIN_SALESREP ||
        recievedMessag.destination !== UIGroup.MANAGE_SALESREP ||
        recievedMessag.destination !== UIGroup.MANAGE_USER ||
        recievedMessag.destination !== UIGroup.EDIT_USER ||
        recievedMessag.destination !== UIGroup.MANAGE_EAPPS_URL ||
        recievedMessag.destination !== UIGroup.MANAGE_CONTENT ||
        recievedMessag.destination !== UIGroup.BUSINESS_SEGMENT)
    ) {
      this.salesrepUIMessageHandle(recievedMessag, 'transfer');
    } else {
      this.sendMsgAfterLoadChildWhenTransferMsg(recievedMessag);
    }
  }

  sendMsgAfterLoadChildWhenTransferMsg(recievedMessag) {
    this.logger.debug('---- transfer message ----', UIGroup.PORTAL, 'app.component.ts');
    this.loadChild(CONFIG[recievedMessag.destination].name).then(
      () => {
        this.sendMessage();
        window.location.href = this.routePathUnderPage;
        this.childLoaded = true;
      },
      () => {
        this.childLoaded = true;
      }
    );
  }

  leavePageAlertMessageHandle(recievedMessag) {
    this.childMessageService.updateCanDeactiveChildApp(recievedMessag.payload.canDeactive);
    if (recievedMessag.payload.type === 'exit' && this.childMessageService.getManagedUrl()) {
      // not presetting, all the other is presetting
      // this.childMessageService.updateCanDeactiveChildApp(false);
      this.handleChildPath(this.childMessageService.getManagedUrl());
      this.childMessageService.resetUrlManage();
    }
  }

  salesrepUIMessageHandle(recievedMessage, action?) {
    if (recievedMessage.payload.brand) {
      if (action && action === 'transfer') {
        this.IESelfStorageFireFlag = false;
      }
      this.cookieService.put('bid', recievedMessage.payload.brand, this.options);
      localStorage.setItem('bid', recievedMessage.payload.brand);
      localStorage.removeItem('bid');
      this.brand = recievedMessage.payload.brand;
    }
    if (recievedMessage.payload.dealerId) {
      this.childMessageService.updateDid(recievedMessage.payload.dealerId);
    }
    if (recievedMessage.payload.coname) {
      this.outletHomeService.setCompanyName(recievedMessage.payload.coname);
    }
    if (action && action === 'transfer') {
      sessionStorage.setItem('salesToExt', JSON.stringify(this.sentMessage.payload));
      this.logger.debug('---- salesToExt set up ---', sessionStorage.getItem('salesToExt'), UIGroup.PORTAL, 'app.component.ts');
      this.brandEmulated(this.routePathUnderPage);
      return;
    }
    this.brandEmulated('#/home');
  }

  backToDefaultLoadedStatus() {
    Object.keys(CONFIG).forEach((key) => {
      CONFIG[key].loaded = false;
    });
  }

  handleChildPath(childInfo) {
    if (childInfo.child) {
      this.childLoaded = false;
      this.navigateAway(childInfo.child, childInfo.path);
    } else {
      window.location.href = childInfo.path;
      const programCookie = this.cookieService.getObject('pid') as DealerProgram;
      if (childInfo.refresh || programCookie.vendorcode !== this.brandingService.selectedProgram.vendorcode) {
        window.location.reload();
      }
    }
  }

  handleSelectedLanguage(selectedLanguage) {
    const abbrv = selectedLanguage.locale.language.split('-')[0];
    this.selectedLanguage = abbrv.toUpperCase();
  }

  /**
   * @param child
   * @param path
   * Comment:
   * Used for the top navigation and refresh page
   * But if this is the first time to load child ui, it will reject after load child
   * Instead go to firstTimeLoadChild()
   * When click on the header or navigation bar, the routePathUnderPage isupdated
   * But when refresh the page, the routePathUnderPage is still null
   */
  navigateAway(child, path?) {
    this.routePathUnderPage = path;
    this.declareSendMessage(child);
    this.loadChild(child).then(
      () => {
        this.sendMessage();
        if (path) {
          window.location.href = path;
        }
        this.childLoaded = true;
      },
      () => {
        this.childLoaded = true;
      }
    );
  }

  declareSendMessage(child) {
    this.sentMessage = {
      payload: {
        dealerId: this.childMessageService.getDid(),
        brand: this.brand,
      },
      departure: UIGroup.PORTAL,
      passThrough: child,
      destination: child,
    };
    this.addMessage(child);
    if (CONFIG[child].pathRelativeToPortal === window.location.hash) {
      this.sentMessage.payload.refreshChildUI = child;
    }
  }

  addMessage(child) {
    this.sentMessage.payload.logLevel = this.logSetupService.getLogLevel;
    if (child === UIGroup.PE_UI) {
      this.sentMessage.payload.coname = this.outletHomeService.getStaticCompanyName();
      this.sentMessage.payload.user = this.loginService.getUser();
      this.sentMessage.payload.isSalesrep = this.guardService.getInternalUser() === 'true';
    }
    if (child === UIGroup.CA_IDEALEASE_UI || child === UIGroup.CA_UI) {
      this.sentMessage.payload.isSalesrep = this.guardService.getInternalUser() === 'true';
      if (this.brandingService.programAttributes?.dorfProgram) {
        this.sentMessage.payload.dorfProgram = this.brandingService.programAttributes.dorfProgram === 'Y';
      }
    }
    if (child === UIGroup.DMC_UI) {
      this.sentMessage.payload.isSalesrep = this.guardService.getInternalUser() === 'true';
    }
    if (child === UIGroup.ADMIN_EXT || child === UIGroup.PROFILE) {
      this.sentMessage.payload.isSalesrep = this.guardService.getInternalUser() === 'true';
      this.sentMessage.payload.user = this.loginService.getUser();
    }
    if (
      child === UIGroup.SALESREP_UI ||
      child === UIGroup.ADMIN_SALESREP ||
      child === UIGroup.MANAGE_CONTENT ||
      child === UIGroup.EDIT_USER ||
      child === UIGroup.MANAGE_SALESREP ||
      child === UIGroup.MANAGE_USER ||
      child === UIGroup.BUSINESS_SEGMENT ||
      child === UIGroup.MANAGE_EAPPS_URL
    ) {
      this.sentMessage.payload.adminIntPermission = CONFIG[UIGroup.ADMIN_SALESREP].permitted;
      this.sentMessage.payload.userAdminPermission = CONFIG[UIGroup.MANAGE_USER].permitted;
      this.sentMessage.payload.contentPermission = CONFIG[UIGroup.MANAGE_CONTENT].permitted;
      this.sentMessage.payload.eappsURLPermission = CONFIG[UIGroup.MANAGE_EAPPS_URL].permitted;
      this.sentMessage.payload.businessSegmentPermission = CONFIG[UIGroup.BUSINESS_SEGMENT].permitted;
      this.sentMessage.payload.eSalesrep = CONFIG[UIGroup.E_SALESREP].permitted;
      this.sentMessage.payload.salesrepPermission = CONFIG[UIGroup.SALESREP_UI].permitted;
      this.sentMessage.payload.manageSalesrep = CONFIG[UIGroup.MANAGE_SALESREP].permitted;
    }
  }

  loadChild(child): Promise<null | void> {
    return new Promise<void>((resolve, reject) => {
      if (!CONFIG[child].permitted) {
        this.childLoaded = true;
        this.errorInfoService.updateErrorMessage('You are not allowed to enter this page');
        window.location.href = '#/error';
        reject();
        return;
      }
      if (!CONFIG[child].loaded) {
        if (isDevMode()) {
          this.renderChild(CONFIG[child].position, window.location.origin + CONFIG[child].fileLocation, child, reject);
        } else {
          this.logger.debug('---- begin load child ----', UIGroup.PORTAL, 'app.component.ts');
          this.renderChild(CONFIG[child].position, CONFIG[child].fileLocation, child, reject);
        }
      } else {
        resolve();
      }
    });
  }

  renderChild(position, url, childName, reject) {
    this.logger.debug('---- begin render child ----', UIGroup.PORTAL, 'app.component.ts');
    this.zone.runOutsideAngular(() => {
      this.logger.debug('---- run outside ----', UIGroup.PORTAL, 'app.component.ts');
      this.jqueryLoad(position, url, () => {
        this.logger.debug('---- call back start ----', UIGroup.PORTAL, 'app.component.ts');
        this.zone.run(() => {
          CONFIG[childName].loaded = true;
          if (CONFIG[childName].shared) {
            CONFIG[childName].shared.forEach((sharedUI) => {
              CONFIG[sharedUI].loaded = true;
            });
          }
          this.logger.debug('---- child loaded finish ----', UIGroup.PORTAL, 'app.component.ts');
          // no confirm loaded message from reports
          if (childName === UIGroup.REPORTS) {
            this.firstTimeLoadChild();
          }
          reject();
        });
      });
    });
  }

  jqueryLoad(position: string, url: string, doneCallback: () => void) {
    $.ajax({
      url,
      type: 'GET',
    })
      .done((childBundles) => {
        if (childBundles.includes('meta name="pagename" content="login.html"') || !childBundles) {
          this.logOffService.logOff();
          return;
        }
        $(position).html(childBundles);
        doneCallback();
      })
      .fail(() => {
        window.location.href = '#/error';
        this.childLoaded = true;
      });
  }

  ngOnDestroy() {
    this.stopListening.forEach((Func) => {
      Func();
    });
  }

  @HostListener('document:click', ['$event', '$event.target'])
  clickOutside(event) {
    if (
      event.target.id.includes('selectLanguageLoginBox') ||
      event.target.id.includes('selectLanguageAbbrvHeader') ||
      event.target.id.includes('selectLanguageAbbrvIHeader') ||
      event.target.id.includes('selectLanguageSaleRep')
    ) {
      this.hideSubNav.next();
      this.showLanguageSelect = !this.showLanguageSelect;
      this.languageService.setLanguageBarStatus(this.showLanguageSelect);
    } else if (this.languageSelectERef && !this.languageSelectERef.nativeElement.contains(event.target)) {
      this.showLanguageSelect = false;
      this.languageService.setLanguageBarStatus(false);
    }

    if (this.topNav && !this.topNav.nativeElement.contains(event.target)) {
      this.hideSubNav.next();
    }
  }
}
