/* eslint-disable import/prefer-default-export */
/* eslint-disable no-template-curly-in-string */
export const BackEndUrl = {
  // before login html
  publicFooter: '/assets/public/${brand}/content/Footer.html',
  terms: '/assets/public/${brand}/content/TermsAndConditions.html',
  contactUsPublic: '/assets/public/${brand}/content/ContactUs.html',

  publicHeader: '/assets/public/${brand}/content/Header.html',
  publicSubBody: '/assets/public/${brand}/content/Subbody.html',
  secure: '/assets/public/${brand}/content/SecurityCustomerAwareness.html',
  advantages: '/assets/public/${brand}/content/Advantages.html',
  help: '/assets/public/${brand}/content/Help.html',

  // after login
  productsService: '/assets/secure/${brand}/content/products-services.html',
  privateSubBody: '/assets/secure/${brand}/content/Subbody.html',
  privateHome: '/assets/secure/${brand}/content/Home.html',
  firstUserHeader: '/assets/secure/${brand}/content/PureHeader.html',
  firstUserDisclaimer: '/assets/secure/${brand}/content/Disclaimer.html',
  fisrtUserRegEnv: '/assets/secure/${brand}/content/Reg-Env.html',
  contactUs: '/assets/secure/${brand}/content/ContactUs.html',
  communication: '/assets/secure/${brand}/content/Communication.html',
  fmv: '/assets/secure/${brand}/content/Fmv-Lease.html',
  loans: '/assets/secure/${brand}/content/standard-loan.html',
  lease: '/assets/secure/${brand}/content/standard-lease.html',
  fmvLease: '/assets/secure/${brand}/content/fmv-lease.html',
  tracLease: '/assets/secure/${brand}/content/Trac-Lease.html',
  uniqueStructure: '/assets/secure/${brand}/content/Unique-Structure.html',
  quasiLease: '/assets/secure/${brand}/content/QuasiLease.html',
  benefitsOfLeasing: '/assets/secure/${brand}/content/Benefits-Leasing.html',
  diffLeaseLoan: '/assets/secure/${brand}/content/Diff-Lease-Loan.html',
  leasingGlossary: '/assets/secure/${brand}/content/Leasing-Glossary.html',
  trainingLib: '/assets/secure/${brand}/content/TrainingLib.html',
  howToSell: '/assets/secure/${brand}/content/how-to-sell-leasing.html',
  troubleshoot: '/assets/secure/${brand}/content/troubleshoot.html',
  questions: '/assets/secure/${brand}/content/Questions.html',
  docLib: '/assets/secure/${brand}/content/DocLib.html',
  sampleDocLib: '/assets/secure/${brand}/content/SampleDocLib.html',

  // internal
  internalLadningHeader: '/assets/public/content/InternalLandingHeader.html',

  // utility
  dateTimeId: '/assets/uuid-date.json',
  logging: './assets/logging-config.json',

  // api
  csrf: '/apigateway-service/csrf',
  jwtToken: '/apigateway-service/jwt',
  sectionAllowed: '/apigateway-service/portal-service/uisections',
  userDetails: '/apigateway-service/portal-service/userdetails',
  companyName: '/apigateway-service/common-service/company/',
  acceptAgreement: '/apigateway-service/admin-service/getDisclaimerAgreementValue',
  clearBackendTokenAndCookies: '/apigateway-service/user-profile-service/landing/savelogoutuserdetails',
  register: '/apigateway-service/admin-service/web/register',
  states: '/apigateway-service/common-service/web/state/',
  getZipCode: '/apigateway-service/common-service/zipCode/',
  verifyUser: '/apigateway-service/admin-service/web/verifyUser/',
  resetPwd: '/apigateway-service/admin-service/web/resetPassword/',
  customerSupportPhoneNO: '/apigateway-service/admin-service/web/customerSupportPhone/',
  localeByBrand: '/apigateway-service/common-service/web/locale/',
  dealerPrograms: '/apigateway-service/common-service/DealerPrograms/',
  brmsProgramAttributes: '/apigateway-service/common-service/brmsProgramAttributes/',

  // credential service
  nfslogIn: '/aac/sps/authsvc/policy/tf_nfs_login',
  tfslogIn: '/aac/sps/authsvc/policy/tf_tfs_login',
  nfs_ca_logIn: '/aac/sps/authsvc/policy/tf_nfs_ca_login',
  tfs_ca_logIn: '/aac/sps/authsvc/policy/tf_tfs_ca_login',
  vef_logIn: '/aac/sps/authsvc/policy/vef_login',
  verifyISAMUserNameEmail: '/aac/sps/authsvc/policy/vef_forgot_pwd',
  updatePassword: '/aac/sps/authsvc/policy/tf_upd_pwd',
  vefUpdatePassword: '/aac/sps/authsvc/policy/vef_upd_pwd',
  forgotPasswordAPIUpdate: '/aac/sps/apiauthsvc/policy/tf_upd_pwd',
  vefForgotPasswordAPIUpdate: '/aac/sps/apiauthsvc/policy/vef_upd_pwd',

  redirectLanding: '/portal/landing',
  logOff: '/pkmslogout',

  disclaimerDoc: '/assets/secure/${brand}/docs/Disclaimers.pdf',
  regularotyDoc: '/assets/secure/${brand}/docs/Regulatory-${brand}.pdf',

  // content
  contentObjectDetails: '/apigateway-service/common-service/contentObjectDetails',
  webContentObjectDetails: '/apigateway-service/common-service/web/contentObjectDetails',
};
