<ng-container *ngIf="!nonStandardHome && home$ | async as home">
  <div [innerHtml]="home | clearNull | safeHtml"></div>
</ng-container>
<div class="row" *ngIf="nonStandardHome">
  <div class="col-md-12 no-gutters">
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="4000">
      <div class="carousel-inner">
        <ng-container *ngFor="let item of carouselData.items; let i = index; first as isFirst">
          <div [ngClass]="isFirst ? 'carousel-item active' : 'carousel-item'">
            <a href="" target="_blank">
              <img class="d-block w-100" [src]="item.contentPath" height="90%" [alt]="item.displayName" />
            </a>
          </div>
        </ng-container>
      </div>

      <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
  <div class="col-md-12 no-gutters" style="margin-top: 15px; text-align: center">
    <p style="color: #646c76">
      Clicking on the carousel image(s) may open a new tab to an external third-party site. Third-party web sites may have privacy and security
      policies different from BMO.
      <br />
      Links to other web sites do not imply the endorsement or approval of such web sites.
    </p>
  </div>
</div>
